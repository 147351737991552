import axios from "./axios";
import { buildResponseArrayGetInterceptor, buildSearchURL } from "@/utils";
import { announce, advertisement, state } from "@/constants";

const baseUrl = "/api/v3/ads";
const { types } = announce[state.ANNOUNCES];
const { adStatus, adCategoriesType } = advertisement[state.ADVERTISEMENT];

const AD_STATUS_NAMES = {
  [adStatus.ACTIVE]: "Active",
  [adStatus.DISABLED]: "Inactive",
};

const FILTERS_VALUES = ["status"];
const FILTER_OPTIONS = {
  status: [
    { id: adStatus.ACTIVE, title: AD_STATUS_NAMES[adStatus.ACTIVE] },
    { id: adStatus.DISABLED, title: AD_STATUS_NAMES[adStatus.DISABLED] },
  ],
};

const AD_TYPES = [{ title: "Pre-roll", value: types.PREROLL }];
const AD_CATEGORIES_TYPES = [
  { title: "All OTT content", value: adCategoriesType.ALL },
  {
    title: "Selected categories",
    value: adCategoriesType.SELECTED,
  },
];

export default {
  getAds(params = { limit: 10, offset: 0 }) {
    return axios
      .get(`${baseUrl}${buildSearchURL(params)}`)
      .then((response) => buildResponseArrayGetInterceptor(response, params));
  },

  deleteAd(id) {
    return axios.delete(`${baseUrl}/${id}`);
  },

  createAd(ad) {
    return axios.post(`${baseUrl}`, ad);
  },

  updateAd(id, ad) {
    return axios.patch(`${baseUrl}/${id}`, ad);
  },

  getAdById(id) {
    return axios.get(`${baseUrl}/${id}`).then((response) => response.data);
  },

  get filtersValues() {
    return FILTERS_VALUES;
  },

  get filterOptions() {
    return FILTER_OPTIONS;
  },

  get statusNames() {
    return AD_STATUS_NAMES;
  },

  get adTypes() {
    return AD_TYPES;
  },

  get adCategoriesTypes() {
    return AD_CATEGORIES_TYPES;
  },
};
