import VPUApi from "./VPUApi";
import AuthApi from "./AuthApi";
import FileApi from "./FileApi";
import EventsApi from "./EventsApi";
import ClubsApi from "./ClubsApi";
import UsersApi from "./UsersApi";
import TeamsApi from "./TeamsApi";
import TenantApi from "./TenantApi";
import SystemApi from "./SystemApi";
import CategoriesApi from "./CategoriesApi";
import MonitoringApi from "./MonitoringApi";
import VidSwapApi from "./VidSwapApi";
import ToolsApi from "./ToolsApi";
import PackagesApi from "./PackagesApi";
import InstallerApi from "./InstallerApi";
import AccountSettingsApi from "./AccountSettingsApi";
import SubscriptionApi from "./SubscriptionApi";
import ExtensionsApi from "./ExtensionsApi";
import AdsApi from "./AdsApi";

export {
  VPUApi,
  AuthApi,
  FileApi,
  TeamsApi,
  ClubsApi,
  UsersApi,
  EventsApi,
  TenantApi,
  SystemApi,
  CategoriesApi,
  MonitoringApi,
  VidSwapApi,
  ToolsApi,
  PackagesApi,
  InstallerApi,
  AccountSettingsApi,
  SubscriptionApi,
  ExtensionsApi,
  AdsApi,
};
